import { useState } from 'react';
import Image from 'next/image';
import { DialogTitle } from '@radix-ui/react-dialog';

import { Dialog, DialogContent, DialogTrigger } from '@/shared/components/ui/dialog';
import { Title } from '@/shared/components/ui/title';
import { cn } from '@/shared/lib/cn';

interface ProductCardProps {
  title: string;
  price?: string;
  image?: string;
  children?: React.ReactNode;
  className?: string;
  noDialog?: boolean;
}

export const ProductCard = ({ title, price, image, children, className, noDialog }: ProductCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(
        'flex flex-col justify-between gap-4 rounded-xl bg-muted p-4 transition-all duration-200 hover:bg-primary-foreground/10 hover:shadow-lg lg:gap-4 lg:p-8',
        className,
      )}
    >
      <div className="flex flex-col gap-2">
        <Title type="h3" className="text-[20px] !font-normal">
          {title}
        </Title>

        {price && <p className="text-[20px]">{price}</p>}
      </div>
      <div className="flex flex-col gap-2">
        {image && !noDialog && (
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <div className="relative flex aspect-square w-full items-center justify-center rounded-md bg-secondary-foreground">
                <Image src={image} alt={title} width={1200} height={1200} className="cursor-pointer object-cover" />
              </div>
            </DialogTrigger>
            <DialogContent
              className="h-[40vh] w-[40vh] border-none bg-transparent p-0 outline-none md:h-[80vh] md:w-[80vh]"
              aria-describedby={undefined}
            >
              <div className="relative m-0 aspect-square h-full w-full bg-transparent p-0">
                <Image className="object-fit m-0 rounded-md p-0" fill src={image} alt={title} loading="lazy" />
              </div>
            </DialogContent>
          </Dialog>
        )}

        {image && noDialog && (
          <div className="relative aspect-square w-full overflow-hidden">
            <Image
              src={image}
              alt={title}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              className="h-full w-full cursor-pointer object-cover"
              loading="lazy"
            />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};
