import { MainLayout } from '@/widgets/layouts/main/main-layout';

import { PageSeo } from '@/shared/lib/seo';

import { Brands } from './brands/brands';
import { Contacts } from './contacts/contacts';
import { Main } from './main';
import { Services } from './services/services';

export const HomePage = () => {
  return (
    <>
      <PageSeo
        title="проверенный поставщик строительного крепежа в Омске"
        description="Азимут - надежный поставщик качественного строительного крепежа торговых марок RAWLPLUG, ELEMENTA, GRAF, FISCHER  в Омске."
      />

      <Main />
      <Brands />
      <Services />
      <Contacts />
    </>
  );
};

HomePage.Layout = MainLayout;
