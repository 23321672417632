export const SLIDER_IMAGES = [
  '/images/slider/slider-0.png',
  '/images/slider/slider-1.png',
  '/images/slider/slider-2.png',
  '/images/slider/slider-3.png',
  '/images/slider/slider-4.png',
  '/images/slider/slider-5.png',
  '/images/slider/slider-6.png',
  '/images/slider/slider-7.png',
];
