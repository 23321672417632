import React, { useRef } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowIcon } from '@/shared/components/icons/arrow';
import { Button } from '@/shared/components/ui/button';
import { cn } from '@/shared/lib/cn';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface CustomSwiperProps {
  children: React.ReactNode;
  id: string;
  className?: string;
  theme?: 'light' | 'dark';
  [key: string]: any;
}

export const CustomSwiper = ({ children, id, className, theme = 'light', ...props }: CustomSwiperProps) => {
  const swiperRef = useRef<SwiperType>();

  return (
    <div className={cn('custom-swiper-container relative w-full', className)} data-theme={theme}>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={10}
        navigation={false}
        pagination={{
          clickable: true,
          el: `.swiper-pagination-${id}`,
        }}
        className="custom-swiper"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        {...props}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>

      <div className="mt-5 flex items-center justify-between">
        <Button onClick={() => swiperRef.current?.slidePrev()} variant="ghost" size="icon-lg" className="z-20">
          <ArrowIcon className="h-8 w-8 rotate-180" style={{ color: 'var(--swiper-arrow-color)' }} />
        </Button>

        <div className={`swiper-pagination-${id} flex flex-grow justify-center`}></div>

        <Button onClick={() => swiperRef.current?.slideNext()} variant="ghost" size="icon-lg" className="z-20">
          <ArrowIcon className="h-8 w-8" style={{ color: 'var(--swiper-arrow-color)' }} />
        </Button>
      </div>
    </div>
  );
};
