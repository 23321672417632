import Link from 'next/link';

import { ProductCard } from '@/shared/components/product-card';
import { Button } from '@/shared/components/ui/button';
import { Title } from '@/shared/components/ui/title';
import { Wrapper } from '@/shared/components/wrapper';

export const Services = () => {
  return (
    <Wrapper>
      <div className="md:mt-15 mt-10 space-y-4 xl:mt-20" id="services">
        <Title type="h2">Услуги</Title>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
          <ProductCard title="Доставка">
            <p>
              Мы ценим комфорт наших покупателей, поэтому быстро и бережно доставляем Ваш заказ в любую точку Омской
              области и России в целом. Доставка товара в пределах г. Омска осуществляться бесплатно.
            </p>
            <Button variant="secondary" className="self-start" asChild>
              <Link href="#contacts">Связаться с&nbsp;нами</Link>
            </Button>
          </ProductCard>

          <ProductCard title="Проведение натурных испытаний">
            <p>
              Для определения прочности креплений проведем натурные испытания анкеров и дюбелей. Составим акт испытаний
              крепежных элементов. Подготовим протокол испытаний нагрузки.
            </p>
            <Button variant="secondary" className="self-start" asChild>
              <Link href="#contacts">Связаться с&nbsp;нами</Link>
            </Button>
          </ProductCard>
        </div>
      </div>
    </Wrapper>
  );
};
